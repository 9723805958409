import {
    axios
} from '@/utils/request'

// 后期扶持项目实施情况统计台账

// 获取列表数据
export function _ProjectImplementationPage(params) {
    return axios({
        url: '/ProjectImplementation/Page',
        method: 'get',
        params,
    })
}

// 新增
export function _ProjectImplementationAdd(params) {
    return axios({
        url: '/ProjectImplementation/Add',
        method: 'post',
        data: params,
    })
}

// 删除
export function _ProjectImplementationDelete(params) {
    return axios({
        url: '/ProjectImplementation/Delete',
        method: 'post',
        data: params,
    })
}


// 编辑
export function _ProjectImplementationEdit(params) {
    return axios({
        url: '/ProjectImplementation/Edit',
        method: 'post',
        data: params,
    })
}



//详情
export function _ProjectImplementationInfo(params) {
    return axios({
        url: '/ProjectImplementation/Info',
        method: 'get',
        params,
    })
}


// excel导入
export function _ProjectImplementationImport(params) {
    return axios({
        url: '/ProjectImplementation/Import',
        method: 'get',
        params,
    })
}


// excel导出
export function _ProjectImplementationExport(params) {
    return axios({
        url: '/ProjectImplementation/Export',
        method: 'get',
        params,
        responseType: 'blob',
    })
}





