<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="后期扶持项目实施情况统计台账"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-row>
              <el-col>
                <div class="leftTitletStyle">市（州）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <div class="leftTitletStyle">县（市、区）</div>
              </el-col>
            </el-row>
            <el-row>
              <div class="leftTitletStyle1">项目名称</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">项目类别</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">对应升级分解下达预算文件文号</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              项目计划批复情况
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">批复时间</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">批复部门</div>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">批复资金（万元）</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">中央资金</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">省级库区资金</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">其他资金</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              项目计划批复情况
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">开工情况</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">是否开工（是/否）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">开工时间</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">完工情况</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">是否完工（是/否）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">完工时间</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">验收情况</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">是否验收（是/否）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">验收时间</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">验收是否合格（是/否）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view3">
            <div class="firstTitletStyle">
              移民资金完成情况
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">截至2022年12月底完成资金（万元）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">截至2023年3月底完成资金（万元）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              实施效益
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">实际收益移民村（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">实际收益移民（人次）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTitletStyle1">备注</div>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle1"></div>
        </el-row>

        <el-row v-for="item in 4" :key="item + Math.random()">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('centralFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('provincialFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('otherFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row v-for="item in 7" :key="item">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('currentYearFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('nextYearFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('benefitedCommunitys') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('benefitedPopulation') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTotalStyle1"></div>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 300px;" v-for="(col, index) in listData" :key="index">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>
                    <el-row v-if="type == 'edit'">
                      <div class="leftTitletStyle"></div>
                    </el-row>
                  </el-col>
                  <el-col>
                    <template v-for="key1 in Object.keys(col)">
                      <el-row v-if="key1 == 'city'">
                        <div class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'countyId'">
                        <div class="contentStyle">
                          <el-cascader  :append-to-body="false"  
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            ref="refDep"
                            size="mini"
                            :options="levalRegion"
                            :props="treeProps"
                            @change="handleChange($event, index)"
                            :show-all-levels="false"
                            placeholder="请选择"
                          >
                          </el-cascader>
                          <div v-else class="box_view">{{ col['county'] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'projectName'">
                        <div class="contentStyle1" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            type="textarea"
                            resize="none"
                            :rows="10"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle1">
                          <div class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'projectCategory'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input size="mini" type="text" v-model="col[key1]" placeholder="请输入"> </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'budgetDocumentNumber'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input size="mini" type="text" v-model="col[key1]" placeholder="请输入"> </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <!-- approvalDate -->
                      <el-row v-if="key1 == 'approvalDate'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-date-picker
                            size="mini"
                            v-model="col[key1]"
                            type="date"
                            format="yyyy-MM-DD"
                            placeholder="选择日期"
                          >
                          </el-date-picker>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? timeFormate(col[key1], 'YYYY/MM/DD') : '--' }}</div>
                        </div>
                      </el-row>

                      <!--批复部门  下拉选择框 -->
                      <el-row v-if="key1 == 'approvalDepartment'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input size="mini" type="text" v-model="col[key1]" placeholder="请输入"> </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'centralFunds'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            min="0"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'provincialFunds'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            min="0"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'otherFunds'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            min="0"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <!-- 是否开工 -->
                      <el-row v-if="key1 == 'isStarted'">
                        <div class="contentStyle">
                          <div v-if="type == 'add' || type == 'edit'">
                            <el-radio v-model="col[key1]" :label="true">是</el-radio>
                            <el-radio v-model="col[key1]" :label="false">否</el-radio>
                          </div>
                          <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'startDate'">
                        <div class="contentStyle">
                          <el-date-picker
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            v-model="col[key1]"
                            type="date"
                            format="yyyy-MM-DD"
                            placeholder="选择日期"
                          >
                          </el-date-picker>

                          <div v-else class="box_view">
                            {{ col[key1] ? timeFormate(col[key1], 'YYYY/MM/DD') : '--' }}
                          </div>
                        </div>
                      </el-row>

                      <!-- 是否完工 -->
                      <el-row v-if="key1 == 'isCompleted'">
                        <div class="contentStyle">
                          <div v-if="type == 'add' || type == 'edit'">
                            <el-radio v-model="col[key1]" :label="true">是</el-radio>
                            <el-radio v-model="col[key1]" :label="false">否</el-radio>
                          </div>
                          <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'completionDate'">
                        <div class="contentStyle">
                          <el-date-picker
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            v-model="col[key1]"
                            type="date"
                            format="yyyy-MM-DD"
                            placeholder="选择日期"
                          >
                          </el-date-picker>

                          <div v-else class="box_view">
                            {{ col[key1] ? timeFormate(col['completionDate'], 'YYYY/MM/DD') : '--' }}
                          </div>
                        </div>
                      </el-row>

                      <!-- 验收情况 -->
                      <el-row v-if="key1 == 'isAccepted'">
                        <div class="contentStyle">
                          <div v-if="type == 'add' || type == 'edit'">
                            <el-radio v-model="col[key1]" :label="true">是</el-radio>
                            <el-radio v-model="col[key1]" :label="false">否</el-radio>
                          </div>
                          <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'acceptanceDate'">
                        <div class="contentStyle">
                          <el-date-picker
                            size="mini"
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            type="date"
                            format="yyyy-MM-DD"
                            placeholder="选择日期"
                          >
                          </el-date-picker>

                          <div class="box_view" v-else>
                            {{ col[key1] ? timeFormate(col[key1], 'YYYY/MM/DD') : '--' }}
                          </div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'isAcceptanceQualified'">
                        <div class="contentStyle">
                          <div v-if="type == 'add' || type == 'edit'">
                            <el-radio v-model="col[key1]" :label="true">是</el-radio>
                            <el-radio v-model="col[key1]" :label="false">否</el-radio>
                          </div>
                          <div class="box_view" v-else>{{ newarr[col[key1] ? 1 : 0] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'currentYearFunds'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'nextYearFunds'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'benefitedCommunitys'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            type="number"
                            min="0"
                            oninput="value=value.replace(/[^\d]/g,'')"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'benefitedPopulation'">
                        <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            type="number"
                            min="0"
                            oninput="value=value.replace(/[^\d]/g,'')"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'remarks'">
                        <div class="contentStyle1" v-if="type == 'add' || type == 'edit'">
                          <el-input
                            size="mini"
                            type="textarea"
                            resize="none"
                            :rows="10"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                        </div>

                        <div v-else class="contentStyle1">
                          <div class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>
                    </template>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _ProjectImplementationAdd,
  _ProjectImplementationInfo,
  _ProjectImplementationEdit
} from '@/api/modular/postImmigrationService/policy/laterStage.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      options: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      newarr: ['否', '是'],
      title1: '',
      title2: ''
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 300 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let res = this.$refs['refDep'][0].getCheckedNodes()[0]
      if (!res.isLeaf) {
        this.listData[index].city = ''
      } else {
        this.listData[index].city = res.parent.label
      }

      let obj = this.getPids(value)

      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      let sum = 0
      for (let i = 0; i < this.listData.length; i++) {
        let ele = this.listData[i]
        sum += Number(ele[name])
      }
      return sum
    },
    refreshYear(year) {
      this.title1 = `截至${this.searchObj.year}年12月底完成资金（万元）`
      this.title2 = `截至${this.searchObj.year + 1}年3月底完成资金（万元）`
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            city: '', //市（州）
            countyId: null, //县（市区）
            county: '', //县（市区）

            projectName: '', //项目名称
            projectCategory: '', //项目类别
            budgetDocumentNumber: '', //对应省级分解下达预算文件文号
            approvalDate: '', //批复时间 项目计划批复情况
            approvalDepartment: '', //批复部门 项目计划批复情况
            centralFunds: null, //中央资金 批复资金（万元） 项目计划批复情况
            provincialFunds: null, //省级库区资金 批复资金（万元） 项目计划批复情况
            otherFunds: null, //其他资金 批复资金（万元） 项目计划批复情况
            isStarted: false, //是否开工 项目实施进度
            startDate: '', //开工时间 项目实施进度
            isCompleted: false, //是否完工 项目实施进度
            completionDate: '', //完工时间 项目实施进度
            isAccepted: false, //是否验收 项目实施进度
            acceptanceDate: '', //验收时间
            isAcceptanceQualified: false, //是否验收合格
            currentYearFunds: null, //当年完成资金 移民资金完成情况
            nextYearFunds: null, //下年3月底完成资金 移民资金完成情况
            benefitedCommunitys: null, //实际受益移民村 实施效益
            benefitedPopulation: null, //实际受益移民 实施效益
            remarks: '', //备注

            countyPids: '' //pids
          }
          if (this.listData.length) {
            let flag = this.listData.every(ele => ele.countyId)
            if (flag) {
              this.listData.splice(this.listData.length, 0, obj)
            } else {
              this.$message.error('请选择县（市区）')
            }
          } else {
            this.listData.splice(this.listData.length, 0, obj)
          }
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },

    submitForm() {
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          centralFunds: Number(ele.centralFunds),
          provincialFunds: Number(ele.provincialFunds),
          otherFunds: Number(ele.otherFunds),
          currentYearFunds: Number(ele.currentYearFunds),
          nextYearFunds: Number(ele.nextYearFunds),
          benefitedCommunitys: Number(ele.benefitedCommunitys),
          benefitedPopulation: Number(ele.benefitedPopulation)
        }
      })

      let flag = listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _ProjectImplementationAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
        case 'edit':
          if (flag) {
            _ProjectImplementationEdit(listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _ProjectImplementationInfo(params).then(res => {
        if (res.code == 200) {
          let arr = [res.data]
          this.title1 = `截至${res.data?.year}年12月底完成资金（万元）`
          this.title2 = `截至${res.data?.year + 1}年3月底完成资金（万元）`

          this.listData = this.formateResData(arr)
        }
      })
    },
    formateResData(arr) {
      return arr.map(ele => {
        return {
          year: ele.year, //年份
          city: ele.city, //县（市区）
          countyId: ele.countyId,
          county: ele.county, //县（市区）
          projectName: ele.projectName ?? '', //项目名称
          projectCategory: ele.projectCategory ?? '', //项目类别
          budgetDocumentNumber: ele.budgetDocumentNumber ?? '', //对应省级分解下达预算文件文号
          approvalDate: ele.approvalDate ?? '', //批复时间 项目计划批复情况
          approvalDepartment: ele.approvalDepartment ?? '', //批复部门 项目计划批复情况
          centralFunds: ele.centralFunds, //中央资金 批复资金（万元） 项目计划批复情况
          provincialFunds: ele.provincialFunds, //中央资金 批复资金（万元） 项目计划批复情况
          otherFunds: ele.otherFunds, //省级库区资金 批复资金（万元） 项目计划批复情况
          isStarted: ele.isStarted,
          startDate: ele.startDate ?? '',
          isCompleted: ele.isCompleted,
          completionDate: ele.completionDate ?? '',
          isAccepted: ele.isAccepted,
          acceptanceDate: ele.acceptanceDate ?? '',
          isAcceptanceQualified: ele.isAcceptanceQualified,
          currentYearFunds: ele.currentYearFunds,
          nextYearFunds: ele.nextYearFunds,
          benefitedCommunitys: ele.benefitedCommunitys,
          benefitedPopulation: ele.benefitedPopulation,
          remarks: ele.remarks,

          countyPids: ele.countyPids, //pids
          id: ele.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
